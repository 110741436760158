'use client'
import { cn } from '@/util/cn'
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import { useFormStatus } from 'react-dom'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>

const buttonClassName = 'text-giphyWhite bg-gradient-primary font-bold px-4 py-2'

export default function Button({ className, children, ...props }: ButtonProps | AnchorProps) {
    return (props as AnchorProps).href ? (
        <a className={cn(buttonClassName, className)} {...(props as AnchorProps)}>
            {children}
        </a>
    ) : (
        <button className={cn(buttonClassName, className)} {...(props as ButtonProps)}>
            {children}
        </button>
    )
}

export function SubmitButton({ className, children, ...props }: ButtonProps) {
    const { pending } = useFormStatus()
    return (
        <button
            type="submit"
            className={cn(buttonClassName, className, pending && `pointer-events-none bg-opacity-50`)}
            {...props}
        >
            {children}
        </button>
    )
}
